<script>
  import { createEventDispatcher } from "svelte";
  import isMobile from "ismobilejs";

  const dispatch = createEventDispatcher();
  let showInstructions = false;
  let iphone = isMobile().apple.phone;

  function addToHome() {
    showInstructions = true;
  }

  function finnish() {
    dispatch("finnished");
  }
</script>

<style>
  .add-to-home {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1337;
    background: #fff;
    display: flex;
    text-align: center;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .container {
    padding-top:64px;
    padding-bottom:64px;
    margin:auto;
  }

  h1,
  p,
  .button {
    margin-bottom: 32px;
  }

  img {
    max-width: 100%;
    height:auto;
    margin-bottom:32px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
  }

  .app-icon {
    border-radius:16px;
  }

  p {
    font-size:0.75rem;
    line-height: 1.5;
  }
</style>

<div class="add-to-home">
  <div class="container">
    {#if !showInstructions}
      <h1 class="h6">Legg web-appen til på hjem-skjermen</h1>
      <p>
        Vi anbefaler alle å legge til web-appen på hjem-skjermen for enklest
        tilgang og best brukeropplevelse. Da vil web-appen legge seg til som en
        vanlig app på hjem-skjermen.
      </p>
      <div class="button button--red" on:click={addToHome}>
        Legg til på hjem-skjerm
      </div>
      <p>
        Hvis du allerede har lagt den til eller kun ønsker å bruke nettleseren kan du gjøre det her:
      </p>
      <div class="button button--ghost" on:click={finnish}>
        Fortsett til innlogging
      </div>
    {:else if iphone}
      <h1 class="h6">Legg til web-appen på iPhone</h1>
      <ol>
        <li>Åpne denne nettsiden i Safari.</li>
        <li>
          <p>Trykk på delingsikonet:</p>
          <img src="/images/help/ios-share.png" alt="" />
        </li>
        <li>
          <p>Bla nedover listen til du finner Hjem-skjerm-knappen:</p>
          <img src="/images/help/ios-add.png" alt="" />
        </li>
        <li>
          <p>
            I eldre versjoner av iOS kan det hende man må bla til høyre i menyen for å finne denne knappen:
          </p>
          <img src="/images/help/ios-old.png" alt="" />
        </li>
        <li>
          <p>
            Når web-appen er lagt til kan du lukke denne nettsiden og åpne den
            fra hjem-skjermen. App-ikonet ser slik ut:
          </p>
          <img src="/logo-180.png" width="80" alt="" class="app-icon" />
        </li>
      </ol>
      <p>
        Hvis du fremdeles ønsker å fortsette i nettleseren kan du gjøre det:
      </p>
      <div class="actions">
        <div class="button button--ghost" on:click={finnish}>
          Fortsett i nettleseren
        </div>
      </div>
    {:else}
      <h1 class="h6">Legg til web-appen på Android</h1>
      <ol>
        <li>
          <p>Trykk på meny-ikonet.</p>
          <img src="/images/help/android-menu.png" alt="" />
        </li>
        <li>
          <p>Trykk på «Legg til på startsiden» (eller tilsvarende):</p>
          <img src="/images/help/android-add.png" alt="" />
        </li>
         <li>
          <p>
            Når web-appen er lagt til kan du lukke denne nettsiden og åpne den
            fra hjem-skjermen. App-ikonet ser slik ut:
          </p>
          <img src="/logo-180.png" width="80" alt="" class="app-icon" />
        </li>
      </ol>
      <p>
        Hvis du fremdeles ønsker å fortsette i nettleseren kan du gjøre det:
      </p>
      <div class="actions">
        <div class="button button--ghost" on:click={finnish}>
          Fortsett i nettleseren
        </div>
      </div>
    {/if}

  </div>
</div>
