<script>
  import { fade } from "svelte/transition";
  import Illustration from "../Illustration.svelte";

  export let data;

  $: userName = data.user.first_name + " " + data.user.last_name;
</script>

<style>
  .planned-activity-item {
    lost-flex-container: row;
    border-bottom: 1px solid color(gray);
  }

  .icon {
    lost-column: 1/4;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  :global(.planned-activity-item .icon svg) {
    width:100%;
    height:auto;
    max-width: 64px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .content {
    lost-column: 3/4;
  }
</style>

<div class="planned-activity-item feed-item" in:fade>
  <div class="icon">
    <Illustration name="calendar" size="64" />
  </div>
  <div class="content">
    <strong>{userName}</strong>
    har meldt seg på
    <strong>{data.planned_activity.name}</strong>
  </div>
</div>
