<script>
  import Icon from "./Icon.svelte";

  export let published = "";
</script>

<style>
  .metas {
    font-size: 0.5rem;
    font-weight: 700;
    display: flex;
    line-height: 1;
  }

  .meta {
    position: relative;
    opacity: 0.5;
    padding-left: 24px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
  :global(.metas .meta .icon) {
    position: absolute;
    left: 0;
    top: -4px;
  }
</style>

<div class="metas">
  <div class="meta">
    <Icon name="clock" size="small" />
    <span>{published}</span>
  </div>
  <!-- <div class="meta">
          <Icon name="chat-message" size="small" />
          <span />
        </div> -->
</div>
