<script>
  import { Workbox } from "workbox-window";
  import Router from "svelte-spa-router";
  import { location, push } from "svelte-spa-router";
  import Login from "./routes/Login.svelte";
  import Logout from "./routes/Logout.svelte";
  import Feed from "./routes/Feed.svelte";
  import Team from "./routes/Team.svelte";
  import Profile from "./routes/Profile.svelte";
  import Challenges from "./routes/Challenges.svelte";
  import Calendar from "./routes/Calendar.svelte";
  import CompletedChallenge from "./routes/CompletedChallenge.svelte";
  import Post from "./routes/Post.svelte";
  import Nav from "./components/Nav.svelte";
  import DesktopNotification from "./components/DesktopNotification.svelte";
  import ProfileButton from "./components/ProfileButton.svelte";
  import RefreshNotice from "./components/RefreshNotice.svelte";
  import isMobile from "ismobilejs";
  import { user } from "stores.js";

  let isPhone = isMobile().phone;
  let updateAviable = false;
  let wbInstance;
  let wb;

if ("serviceWorker" in navigator) {
    wb = new Workbox("/service-worker.js");
    wb.addEventListener("message", event => {
      if (event.data.type === "CACHE_UPDATED") {
        updateAviable = true;
      }
    });

    wb.addEventListener("installed", event => {
      if (event.isUpdate) {
        updateAviable = true;
      }
    });

    wb.register();
  }

  $: if ($location) {
    if (wb && typeof wb.update === "function") {
      wb.update();
    }
  }

  const routes = {
    "/": Team,
    "/profil": Profile,
    "/utfordringer": Challenges,
    "/feed/:type?/:id?": Feed,
    "/feed": Feed,
    "/kalender": Calendar,
    "/innlogging": Login,
    "/utlogging": Logout
  };

  const feedRoutes = ["/feed/aktivitet/", "/feed/post/"];

  $: slideNavRight = feedRoutes.some(path => $location.startsWith(path));
  $: slideNavDown = $location === "/innlogging";

  if (
    typeof $user.access_token === "undefined" &&
    $location !== "/innlogging"
  ) {
    push("/innlogging");
  }
</script>

<style>
  .nav-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    transition: all 0.4s;

    &.slide-right {
      transform: translateX(-100px);
      transition-duration: 0.7s;
      opacity: 0;
    }

    &.slide-down {
      transform: translateY(-56px);
      display: none;
    }
  }

  .profile-button.hidden {
    display: none;
  }
</style>

<Router {routes} />

<div
  class="nav-wrap"
  class:slide-right={slideNavRight}
  class:slide-down={slideNavDown}>

  <Nav />

</div>

<div class="profile-button" class:hidden={slideNavDown}>
  <ProfileButton />
</div>

{#if window.location.hostname !== 'localhost' && !isPhone}
  <DesktopNotification />
{/if}

{#if updateAviable}
  <RefreshNotice />
{/if}
