<script>
  import { fly, fade } from "svelte/transition";
  import { link, push } from "svelte-spa-router";
  import api from "api";
  import { formatNumber } from "helpers";
  import { goals } from "stores.js";
  import { onMount, onDestroy } from "svelte";
  import PercentCircle from "../components/PercentCircle.svelte";
  import Illustration from "../components/Illustration.svelte";
  import Icon from "../components/Icon.svelte";
  import LegerLogo from "../components/LegerLogo.svelte";
  import BarGraph from "../components/BarGraph.svelte";

  let team;
  let members;
  let points;
  let teamGoals;

  let lastSevenDays = {
    series: [[], []],
    labels: []
  };
  let total;

  onMount(() => {
    api.get("team").then(res => {
      team = res.data.team;
      members = res.data.members;
      points = res.data.points;
      lastSevenDays.series = [
        res.data.stats.last_seven_days.team,
        res.data.stats.last_seven_days.average
      ];

      total = res.data.stats.last_seven_days.total;

      teamGoals = {
        helse: $goals.helse * members.length,
        samfunn: $goals.samfunn * members.length
      };
    });
  });

  onDestroy(() => {});
</script>

<style>
  .team {
    padding: 0 0 80px 0;

    p {
      /* font-size:0.75rem;
      line-height: 1.5; */
    }

    > div {
      margin-top: $gutter * 2;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .top {
    min-height: 30vh;
    padding: 15vw 0 33vw 0;
    background: color(red);
    color: #fff;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='376' height='75' viewBox='0 0 376 75'%3E%3Cpath d='M376 0.44219C272.203 -3.90471 143.386 24.2068 0 66.715V74.5H376V0.44219Z' fill='white' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center bottom;

    h1 {
      font-weight: 700;
    }

    &::before {
      position: absolute;
      content: " ";
      width: 100%;
      height: 10px;
      background: #fff;
      bottom: -7px;
      left: 0;
    }
  }

  .categories {
    position: relative;
    text-align: center;
    margin-top: 0 !important;

    .container {
      lost-flex-container: row;

      > div {
        lost-column: 1/2;

        &:first-child .circle-wrap {
          margin-top: -17vw;
        }

        &:last-child .circle-wrap {
          margin-top: -27vw;
        }
      }
    }

    .h5 {
      color: color(red);
      font-weight: 900;
    }

    .overline {
      margin-top: 8px;
      line-height: 1.5;
    }

    .circle-wrap {
      margin: auto;
      /* width: 80%; */
      max-width: 160px;
      background: #fff;
      padding: 8px;
      border-radius: 50%;
    }
  }

  .summary {
    text-align: center;

    .content {
      background: color(off-white);
      padding: 32px;
      border-radius: 16px;
    }

    img {
      margin-bottom: 16px;
      width: 128px;
      height: auto;
    }
  }

  .challenge-cta {
    text-align: center;

    .content {
      /* background: color(red); */
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 32px;
      /* font-size: 0.75rem;
      line-height: 1.5; */
      /* color: color(red); */
      border: 1px solid color(gray);
      border-bottom: none;
      box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
    }

    .icon-button {
      margin: 0;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      background: #fff;
    }
  }

  .graph {
    .content {
      background: color(gray);
      padding: 32px;
      border-radius: 16px;
    }

    .title {
      padding-bottom: 32px;
      font-weight: 700;
    }
  }

  .graph-labels {
    display: flex;
    font-size: 0.75rem;
    line-height: 1;

    > div {
      padding-left: 32px;
      position: relative;
      margin-left: 16px;

      &::before {
        content: " ";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:first-child {
        margin-left: 0;

        &::before {
          background: color(red);
        }
      }
    }
  }

  .feed-cta {
    text-align: center;

    .content {
      border: 1px solid color(gray);
      padding: 32px;
      border-radius: 16px;
    }

    .button {
      display: inline-block;
      margin-top: $gutter;
    }
  }

  .members {
    .title {
      padding-bottom: 24px;
      font-weight: 700;
    }

    .name {
      font-weight: 700;
    }

    .member {
      padding-top: 16px;
      padding-bottom: 16px;
      /* border-bottom: 1px solid color(gray);
      border-top: 1px solid color(gray); */
      background: color(gray);
      lost-flex-container: row;
      align-items: center;
      padding-left:32px;
      padding-right:32px;
      border-bottom:1px solid #fff;

      &:first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      &:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .name {
        lost-column: 3/4;

        .phone {
          position: relative;
          font-size: 0.75rem;
          font-weight: 400;
          padding-left: 24px;

          a {
            color:color(black);
          }
        }
      }

      .points {
        lost-column: 1/4;
        font-weight: 700;
        line-height:1;

        > div {
          width: 56px;
          height: 56px;
          background: #fff;
          text-align: center;
          color: color(red);
          border-radius: 50%;
          display:flex;
          align-items: center;
          justify-content: center;
        }

        span {
          display:block;
          font-size:0.5rem;
        }
      }

      &:nth-child(2n) {

      }
    }
  }

  :global(.team .name .phone svg) {
    position: absolute;
    left:0;
    top:3px;
  }
</style>

<div class="team">
  {#if team}
    <div class="top" in:fly={{ y: -100 }}>
      <div>
        <div class="container">
          <h1 class="h4" in:fly={{ y: 50 }}>{team.name}</h1>
        </div>
      </div>
    </div>

    <div class="categories">
      <div class="container">
        <div in:fly={{ y: 50, delay: 50 }}>
          <div class="circle-wrap">
            <PercentCircle
              percent={Math.min((points.helse / teamGoals.helse) * 100, 100)}>
              <Illustration name="helse" />
            </PercentCircle>
          </div>
          <div class="overline">Helse</div>
          <div class="h5">
            {Math.round((points.helse / teamGoals.helse) * 100)}%
          </div>
        </div>
        <div in:fly={{ y: 50, delay: 100 }}>
          <div class="circle-wrap">
            <PercentCircle
              percent={Math.min((points.samfunn / teamGoals.samfunn) * 100, 100)}>
              <Illustration name="samfunn" />
            </PercentCircle>
          </div>
          <div class="overline">Samfunn</div>
          <div class="h5">
            {Math.round((points.samfunn / teamGoals.samfunn) * 100)}%
          </div>
        </div>
      </div>
    </div>

    <div class="summary" in:fly={{ y: 50, delay: 150 }}>
      <div class="container">
        <div class="content">
          <img
            src="/images/leger-uten-grenser.svg"
            alt=""
            width="76"
            height="22" />
          {#if points.total}
            <p>
              Ditt lag har samlet inn
              <strong>{points.total} poeng</strong>
              og
              <strong>{formatNumber(points.total * 30)} kroner</strong>
              til Leger Uten Grenser.
            </p>
          {:else}
            <p>
              Ditt lag har ikke samlet noen poeng eller kroner til Leger Uten
              Grenser enda. Når kampanjen starter på mandag kan dere fullføre
              utfordringer for å samle poeng.
            </p>
          {/if}
        </div>
      </div>
    </div>

    <!-- <div class="challenge-cta" in:fly={{ y: 50, delay: 200 }} on:click={() => {
      push('/utfordringer');
    }}>
      <div class="container">
        <div class="content">
          {#if points.total === 0}
            <p>
              Kom i gang med å samle poeng og penger til Leger Uten Grenser. Se
              ukens utfordringer her:
            </p>
          {:else}
            <p>
              Hvilke utfordringer vil
              <br />
              dere ta i dag?
            </p>
          {/if}
        </div>
        <div class="icon-button">
          <div class="icon">
            <Illustration name="star" />
          </div>
          <div class="text">
            <span>Velg utfordring</span>
          </div>
        </div>
      </div>
    </div> -->

    <div
      class="challenge-cta"
      in:fly={{ y: 50, delay: 200 }}
      on:click={() => {
        push('/kalender');
      }}>
      <div class="container">
        <div class="content">
          <p>
            I kalenderen kan du se og enkelt melde deg på planlagte
            fellesaktiviteter:
          </p>
        </div>
        <div class="icon-button">
          <div class="icon">
            <Illustration name="calendar" />
          </div>
          <div class="text">
            <span>Se kalenderen</span>
          </div>
        </div>
      </div>
    </div>

    <div class="graph">
      <div class="container">
        <div class="content">
          <div class="overline title">
            Utfordringer fullført
            <br />
            siste 7 dager
          </div>
          <BarGraph {...lastSevenDays} height="100" />
          <div class="graph-labels">
            <div class="graph-label">{team.name}</div>
            <div class="graph-label">Gjennom­snitt</div>
          </div>
        </div>
      </div>
    </div>

    <div class="feed-cta">
      <div class="container">
        <div class="content">
          <p>De siste 7 dagene er det blitt fullført {total} utfordringer.</p>

          <a href="/feed" use:link class="button button--red">Se feeden</a>
        </div>
      </div>
    </div>

    <div class="members">
      <div class="container">
        <div class="overline title">Lagmedlemmer</div>
        <div class="content">
          {#each members as member}
            <div class="member">
              <div class="name">
                <div class="">{member.first_name} {member.last_name}</div>
                <div class="phone">
                  <Icon name="phone" size="small" />
                  <a href="tel:{member.phone}">{member.phone}</a>
                </div>
              </div>
              <div class="points">
                <div>
                  <div>
                    {member.points.total}
                    <span>poeng</span>
                  </div>
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {:else}
    <!-- loader -->
  {/if}
</div>
