<script>
  import api from "api";
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import { user } from "stores.js";

  function goodbye() {
    user.remove();
    window.location.hash = '/innlogging';
    window.location.reload();
  }

  onMount(() => {
    api
      .post("auth/logout")
      .then(goodbye)
      .catch(goodbye);
  });
</script>
