<script>
  import { onMount, onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import Metas from "../Metas.svelte";
  import Badge from "../Badge.svelte";

  export let data = {
    id: 0,
    challenge: null,
    published: "",
    description: "",
    participants: [],
    attachments: []
  };

  $: formattedParticipants = data.participants
    .map(p => p.first_name)
    .reduce((text, name, index, arr) => {
      if (index === arr.length - 1) {
        text += " og ";
      } else if (index <= arr.length - 2) {
        text += ", ";
      }

      return text + name;
    });

  $: formattedTeams = data.participants
    .map(p => "<span class='team'>" + p.team.name + "</span>")
    .filter((name, index, arr) => arr.indexOf(name) === index)
    .reduce((text, name, index, arr) => {
      if (index === arr.length - 1) {
        text += " og ";
      } else if (index <= arr.length - 2) {
        text += ", ";
      }

      return text + name;
    });

  $: formattedAttachments = data.attachments.slice(0, 3);
</script>

<style>
  .completed-challenge-item {
    lost-flex-container: row;
    border-bottom: 1px solid color(gray);
  }

  .icon {
    lost-column: 1/4;
    position: relative;
  }

  .content {
    lost-column: 3/4;
  }

  .text {
    /* width: 90%; */
    margin-bottom: 14px;
  }

  .attachments {
    margin-top: 24px;
  }

  img {
    height: 72px;
    width: 72px;
    position: relative;
    display: inline-block;
    border-radius: 16px;
    border: 2px solid #fff;
    background: color(gray);

    + img {
      margin-left: -48px;
    }

    &:nth-child(1) {
      z-index: 3;
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 1;
    }

    @media(max-width: 330px) {
      &:nth-child(3) {
        display:none;
      } 
    }
  }

  .read-more {
    display: inline-block;
    margin-left: 16px;
    width: 72px;
    height: 72px;
    border-radius: 16px;
    line-height: 72px;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    background: #f8f8f8;
    color: color(red);
    vertical-align: top;
  }

  :global(.completed-challenge-item .text span) {
    font-weight: 700;
  }

  :global(.completed-challenge-item .text .team) {
    color: color(red);
  }
</style>

<a
  class="feed-item feed-item--std completed-challenge-item"
  in:fade
  href="/#/"
  on:click|preventDefault={() => {
    push('/feed/aktivitet/' + data.id);
  }}>
  <div class="icon">
      <Badge name={data.challenge.icon} />
  </div>
  {#if data.participants.length}
    <div class="content">
      <div class="text">
        {@html formattedParticipants}
        fra
        {@html formattedTeams}
        fullførte {data.challenge.name}
      </div>
      <Metas published={data.published} />
      <div class="attachments">
        {#each formattedAttachments as attachment}
          <img
            src="{attachment.url}?w=144&h=144&fit=crop"
            alt=""
            width="72"
            height="72" />
        {/each}
        <div class="read-more">Les mer &hellip;</div>
      </div>
    </div>
  {/if}
</a>
