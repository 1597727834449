<script>
  import api, { refreshToken } from "api";
  import { user } from "stores.js";
  import { onMount, onDestroy } from "svelte";
  import { push } from "svelte-spa-router";
  import Illustration from "./Illustration.svelte";
  import Icon from "./Icon.svelte";
  import Badge from "./Badge.svelte";
  import Switch from "./Switch.svelte";
  import Uppy from "@uppy/core";
  import FileInput from "@uppy/file-input";
  import XHRUpload from "@uppy/xhr-upload";
  import StatusBar from "@uppy/status-bar";
  import ThumbnailGenerator from "@uppy/thumbnail-generator";
  import Select from "svelte-select";
  import Swal from "sweetalert2";

  export let challenge = null;

  let uppy;
  let uppyInputElm;
  let uppyStatusElm;
  let users = [];
  let errorMessages = [];
  let submitting = false;

  let addedFiles = [];
  let publishImages = true;
  let description;
  let selectedUsers;

  onMount(() => {
    api.get("user").then(res => {
      users = res.data.data.map(user => {
        return {
          value: user.id,
          label: user.name
        };
      });
    });

    uppy = new Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: 5,
        minNumberOfFiles: 1,
        allowedFileTypes: ["image/*"]
      },
      locale: {
        strings: {
          youCanOnlyUploadX: {
            0: "Du kan kun laste opp ett bilde",
            1: "Du kan kun laste opp %{smart_count} bilder"
          },
          youHaveToAtLeastSelectX: {
            0: "Du må laste opp minst ett bilde som bevis for utført utfordring",
            1: "Du må minst laste opp %{smart_count} bilder"
          },
          youCanOnlyUploadFileTypes: "Du kan kun laste opp bilder",
          companionError: "Tilkobling misslyktes"
        }
      }
    });

    uppy.use(XHRUpload, {
      endpoint: API_URL + "upload",
      headers: {
        authorization: `Bearer ${$user.access_token}`,
        Accept: "application/json"
      }
    });

    uppy.use(FileInput, {
      target: uppyInputElm,
      pretty: false,
      inputName: "files[]",
      locale: {}
    });

    uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 200,
      waitForThumbnailsBeforeUpload: false
    });

    uppy.use(StatusBar, {
      target: uppyStatusElm,
      hideUploadButton: true,
      locale: {
        strings: {
          uploading: "Laster opp",
          complete: "Fullført",
          uploadFailed: "Opplasting feilet",
          paused: "Pasuet",
          retry: "Prøv på nytt",
          cancel: "Avbryt",
          retryUpload: "Prøv på nytt",
          pauseUpload: "Pause opplasting",
          resumeUpload: "Fortsett opplasting",
          cancelUpload: "Avbryt opplasting",
          filesUploadedOfTotal: {
            0: "%{complete} av %{smart_count} bilde lastet opp",
            1: "%{complete} av %{smart_count} bilder lastet opp"
          },
          dataUploadedOfTotal: "%{complete} av %{total}",
          xTimeLeft: "%{time} igjen",
          uploadXFiles: {
            0: "Last opp ett bilde",
            1: "Last opp %{smart_count} bilder"
          },
          uploadXNewFiles: {
            0: "Last opp +%{smart_count} bilde",
            1: "Last opp +%{smart_count} bilder"
          }
        }
      }
    });

    uppy.on("thumbnail:generated", (file, preview) => {
      addedFiles = uppy.getFiles();
    });

    uppy.on("file-added", (file, preview) => {
      addedFiles = uppy.getFiles();
    });
  });

  function removeFile(file) {
    uppy.removeFile(file.id);
    addedFiles = uppy.getFiles();
  }

  function submit() {
    if (submitting) return;
    submitting = true;

    // make sure token don't expire while uploading
    refreshToken()
      .then(() => {
        const xhr = uppy.getPlugin('XHRUpload');
        xhr.opts.headers.authorization = `Bearer ${$user.access_token}`;
        
        return uppy.upload();
      })
      .then(results => {
        let uploadedFiles = [];

        results.successful.forEach(result => {
          result.response.body.tmp_files.forEach(path => {
            uploadedFiles.push(path);
          });
        });

        let payload = {
          challenge_id: challenge.id,
          uploaded_files: uploadedFiles
        };

        if (description) {
          payload["description"] = description;
        }

        if (selectedUsers) {
          payload["users"] = selectedUsers.map(u => u.value);
        }

        payload["publish_images"] = publishImages;

        return api.post("completed-challenge", payload);
      })
      .then(res => {
        submitting = false;

        if (res.data.status === "error") {
          errorMessages = [res.data.message];
          return;
        }

        window.scrollTo(0, 0);
        push("/feed");
      })
      .catch(err => {
        submitting = false;

        Swal.fire({
          title: "Kunne ikke fullføre",
          text: err.message,
          type: "error",
          confirmButtonText: "Prøv igjen"
        });
      });
  }

  onDestroy(() => {
    if (uppy) {
      uppy.close();
    }
  });
</script>

<style>
  .submit-challenge {
    padding: 0 0 200px 0;
    /* background: color(off-white); */
  }

  .entry {
    padding: 0 32px 64px 80px;
    position: relative;

    h2 {
      margin-bottom: 16px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1.5;
    }

    &::before {
      content: " ";
      position: absolute;
      left: 40px;
      width: 1px;
      height: 100%;
      top: 0;
      background: color(gray);
    }
  }

  .circle {
    width: 40px;
    height: 40px;
    background: color(red);
    color: #fff;
    line-height: 40px;
    text-align: center;
    position: absolute;
    left: 20px;
    top: -4px;
    border-radius: 50%;
  }

  .intro {
    padding-top: 48px;

    .icon {
      width: 64px;
      margin-bottom: 16px;
    }

    h1 {
      color: color(red);
    }

    &::before {
      content: " ";
      position: absolute;
      left: 40px;
      width: 1px;
      height: 100%;
      top: 0;
      background: color(gray);
    }
  }

  .icon-button {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .uppy-input {
    display: none;
  }

  .file {
    padding-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.5;
    height: 64px;
    background: color(gray);
    margin-bottom: 1px;

    &:nth-child(2n) {
      padding-right: 0px;
    }

    &:last-child {
      margin-bottom: 32px;
    }

    .preview {
      flex: 0 0 64px;
      height: 100%;
      background: color(gray);
    }

    .details {
      flex: 1 1;
      padding: 16px 40px 16px 16px;
      position: relative;
      overflow: hidden;
    }

    .remove {
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: color(red);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #fff;
    }
  }

  :global(.submit-challenge .file svg) {
    stroke: #fff;
    transform: rotate(45deg);
  }

  .switch {
    margin-top: 16px;
  }

  textarea {
    /* background: color(gray); */
    border: 1px solid color(gray);
    height: 100px;
    width: 100%;
    border-radius: 4px;
    margin-top: 32px;
    padding: 16px;
    font-size: 16px;
    font: inherit;

    &:focus {
      outline: none;
      border-color: color(red);
    }

    -webkit-appearance: none;
  }

  .participants {
    margin-top: 32px;
  }

  :global(.submit-challenge .participants .multiSelect) {
    border-radius: 4px;
    border-color: color(gray);
  }

  :global(.submit-challenge .participants .multiSelect.focused) {
    border-color: color(red);
  }

  .submit {
    text-align: center;
    margin-top: 32px;
    padding: 0;
    height: 64px;
    line-height: 64px;
  }

  .uppy-status {
    margin-top: 16px;
  }
</style>

<div class="submit-challenge">

  <div class="entry intro">
    <div class="icon">
      <Badge name={challenge.icon} />
    </div>
    <div class="overline">Fullfør utfordring:</div>
    <h1 class="h4">{challenge.name}</h1>
  </div>

  <div class="entry">
    <div class="circle">1</div>
    <h2 class="h5">Bilder</h2>
    <p>
      Velg minst ett bilde som beviser at du har utført utfordringen. Du må
      gjerne velge opptil fem bilder. Disse bildene kan du velge å offentligjøre
      i feeden.
    </p>
    <div
      class="icon-button"
      on:click={() => {
        uppyInputElm.querySelector('input').click();
      }}>
      <div class="icon">
        <Illustration name="images" />
      </div>
      <div class="text">
        <span class="h6">Velg bilder</span>
      </div>
    </div>

    <div bind:this={uppyInputElm} class="uppy-input" />

    <div class="added-files">
      {#each addedFiles as file}
        <div class="file">
          <div class="preview" style="background-image:url({file.preview})" />

          <div class="details">{file.name}</div>

          <div
            class="remove"
            on:click={() => {
              removeFile(file);
            }}>
            <Icon name="plus" size="small" />
          </div>
        </div>
      {/each}
    </div>

    <p>Vil du publisere bildene i feeden?</p>
    <div class="switch">
      <Switch bind:yes={publishImages} />
    </div>
  </div>

  <div class="entry">
    <div class="circle">2</div>
    <h2 class="h5">Melding</h2>
    <p>
      Hvis du ønsker kan du skrive en liten melding som vil legges ved
      aktiviteten. Denne vil kunne leses av alle.
    </p>
    <textarea
      bind:value={description}
      class="description"
      placeholder="Skriv meldingen her..." />
  </div>

  <div class="entry">
    <div class="circle">3</div>
    <h2 class="h5">Andre deltakere</h2>
    <p>
      Hvis dere var flere sammen kan du legge til de andre deltakerne her. Kun
      én av dere trenger å registrere utfordringen: Alle deltakere vil få
      registrert utfordringen som fullført.
    </p>
    <div class="participants">
      <Select
        items={users}
        isMulti={true}
        bind:selectedValue={selectedUsers}
        placeholder="Velg..." />
    </div>
  </div>

  <div class="container">
    <div class="submit button button--red" on:click={submit}>
      {submitting ? 'Fullfører...' : 'Fullfør'}
    </div>
    <div class="uppy-status" bind:this={uppyStatusElm} />
    <div class="error-messages">
      {#each errorMessages as message}
        <p>{message}</p>
      {/each}
    </div>
  </div>
</div>
