<script>
  import api from "api";
  import { onMount, onDestroy } from "svelte";
  import { push } from "svelte-spa-router";
  import Icon from "../components/Icon.svelte";
  import Logo from "../components/Logo.svelte";
  import AddToHome from "../components/AddToHome.svelte";
  import { user, appMode } from "stores.js";

  let hideAddToHome = false;

  let phone;
  let token;
  let submitting = false;
  let smsSent = false;
  let error = false;

  function sendSms() {
    if (submitting) return;

    submitting = true;
    error = false;

    api
      .post("auth/send-sms", {
        phone: phone
      })
      .then(res => {
        if (res.data.status === "success") {
          smsSent = true;
        } else {
          error = "Fant ikke mobilnummeret, prøv på nytt.";
        }

        submitting = false;
      })
      .catch(err => {
        error = "Fant ikke mobilnummeret, prøv på nytt.";
        submitting = false;
      });
  }

  function verifyToken() {
    if (submitting) return;

    submitting = true;
    error = false;

    api
      .post("auth/verify-token", {
        phone: phone,
        token: token
      })
      .then(res => {
        if (typeof res.data.access_token !== "undefined") {
          user.set(res.data);
          push("/");
        } else {
          error = "Kunne ikke validere koden, prøv på nytt.";
        }

        submitting = false;
      })
      .catch(err => {
        console.log(err);
        submitting = false;
      });
  }

  function reset() {
    token = '';
    smsSent = false;
    error = false;
  }
</script>

<style>
  section {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-flow: column;
    min-height: 100vh;
  }

  .logo {
    flex: 1 1;
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(red);
    padding-bottom: 15vw;
  }

  .logo-width {
    width: 60%;
    max-width: 230px;
    padding: 16px 0;
  }

  .login {
    flex: 0 1;
    width: 100%;
    margin-bottom: 0;
    /* margin: auto; */
    padding: 40px 16px 72px 16px;
    /* max-width: 300px; */
    position: relative;
    text-align: center;
  }

  form {
    width: 100%;

    input[type="tel"],
    input[type="number"] {
      width: 100%;
      border: none;
      padding: 16px 24px 16px 48px;
      border-bottom: 1px solid color(red);
      font-size: 24px;
      border-radius: 0;

      &:focus {
        outline: none;
      }
    }

    .button {
      margin-top: 16px;
      border-radius: 32px;
      width: 100%;
      -webkit-appearance: none;
    }

    &.hidden {
      display: none;
    }
  }

  label {
    margin-bottom: 16px;
    display: block;
    position: relative;
  }

  :global(.login .icon) {
    position: absolute;
    bottom: 16px;
    left: 0;
    stroke: color(red) !important;
  }

  :global(.login input + .icon) {
    stroke-width: 4px !important;
  }

  .back {
    color: color(red);
  }

  .error {
    margin-top: 16px;
  }

  .info {
    margin-top: 16px;
    font-size: 0.75rem;
    line-height: 1.5;

    a {
      color: color(red);
    }
  }
</style>

<section>
  <div class="logo">
    <div class="logo-width">
      <Logo />
    </div>
  </div>
  <div class="login">
    <svg width="376" height="104" viewBox="0 0 376 80" class="wave">
      <path
        d="M376 0.5C272.203 -3.8469 143.386 48.2069 0 90.7151V103.5H376V0.5Z" />
    </svg>

    <form action="" on:submit|preventDefault={sendSms} class:hidden={smsSent}>
      <label>
        <input
          class="text"
          type="tel"
          name="phone"
          bind:value={phone}
          placeholder="Ditt mobilnummer" />
        <Icon name="phone" size="medium" />
      </label>
      {#if !submitting}
        <input
          class="button button--red"
          type="submit"
          value="Få tilsendt engangskode" />
      {:else}
        <input
          class="button button--ghost"
          type="submit"
          disabled
          value="Sender..." />
      {/if}

    </form>

    <form
      action=""
      on:submit|preventDefault={verifyToken}
      class:hidden={!smsSent}>
      <a href="#back" class="back" on:click|preventDefault={reset}>
        &larr; Tilbake
      </a>
      <label>
        <input
          class="text"
          type="number"
          name="phone"
          bind:value={token}
          placeholder="Skriv inn koden fra SMS" />
        <Icon name="phone-sms" size="medium" />
      </label>

      {#if !submitting}
        <input class="button button--red" type="submit" value="Logg inn" />
      {:else}
        <input
          class="button button--ghost"
          type="submit"
          disabled
          value="Logger inn..." />
      {/if}
    </form>

    {#if error}
      <div class="error">{error}</div>
    {/if}

    <div class="info">
      Hvis du vil registrere deg eller trenger hjelp kan du sende e-post til
      <a href="mailto:utengrenser@hennig-olsen.no">
        utengrenser@hennig-olsen.no
      </a>
    </div>
  </div>
</section>

{#if !$appMode && !hideAddToHome}
  <AddToHome on:finnished={() => (hideAddToHome = true)} />
{/if}
