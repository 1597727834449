<script>
  import { onMount, onDestroy } from "svelte";

  export let iframe;

  let videoWidth;
  let videoHeight;
  let videoSrc;
  let mounted = false;

  let started = false;

  $: if (iframe && mounted) {
    let matches = iframe.match(/width=\"([^"]*)\"/);

    if (matches && matches[1]) {
      videoWidth = parseInt(matches[1]);
    }

    matches = iframe.match(/height=\"([^"]*)\"/);

    if (matches && matches[1]) {
      videoHeight = parseInt(matches[1]);
    }
  }

  onMount(() => {
    mounted = true;
  });
</script>

<style>
  .video-width {
    width: 100%;
    position: relative;
  }

  :global(.video-width iframe) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
</style>

<div class="video-width">
  <div
    class="video-height"
    style="padding-bottom:{Math.round((videoHeight / videoWidth) * 100)}%">
    {@html iframe}
  </div>
</div>
