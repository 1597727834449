<script>
  export let name = "";
  export let size = 64;
</script>

<style>
  .illu {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: var(--illu-stroke-width, 2px);
    stroke-linejoin: round;
    stroke-linecap: round;
  }
</style>

<svg class="illu" width="{size}" height="{size}">
  <use xlink:href="#{name}" />
</svg>
