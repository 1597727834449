<script>
  export let yes;

  function toggle() {
    yes = !yes;
  }
</script>

<style>
  .switch {
    width: 80px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    position: relative;
    transition: background 0.2s ease-out;

    &:after {
      content: " ";
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.1s ease-out;
    }

    &:hover {
      cursor: pointer;
    }

    > div {
      position: absolute;
      height:100%;
      top:0;
      display:flex;
      align-items:center;
      padding: 0 16px;
      font-size: 0.75rem;
    
      &:first-child {
        left: 0;
        color:#fff;
      }

      &:last-child {
        right: 0;
        color: color(black);
      }
    }

    &.yes {
      background: color(red);

      &:after {
        transform: translateX(100%);
      }
    }
  }

  input {
    display: none;
  }
</style>

<div class="switch" class:yes on:click={toggle}>
  <div>Ja</div>
  <div>Nei</div>
</div>

<input type="checkbox" bind:checked={yes} />
