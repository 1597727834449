<script>
  import Logo from "./Logo.svelte";

  let domain = window.location.hostname;
</script>
<style>
  .desktop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: color(red);
    color:#fff;
    display: flex;
    z-index: 13337;
  }

  .content {
    margin:auto;
    max-width:600px;
    text-align:center;
    padding: 0 32px;
  }

  .logo {
    max-width: 200px;
    margin:0 auto 32px auto;
  }

  p {
    margin-bottom: 32px;
  }
</style>

<div class="desktop">

  <div class="content">
    <div class="logo">
      <Logo />
    </div>
    <p class="h5">
      Denne webappen er lagd for mobil. Besøk denne siden på mobilen for å fortsette:
    </p>

    <p class="h5">{domain}</p>
  </div>

</div>
