<script>
  export let name = "";
  export let size = "large";

  const sizes = {
    large: 64,
    medium: 32,
    small: 16
  };

  $: selectedSize = sizes[size];
</script>

<style>
  .icon {
    fill: none;
    stroke: var(--icon-color, #000);
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;

    &.small {
      stroke-width: 4px;
    }
  }
</style>

<svg class="icon {size}" width={selectedSize} height={selectedSize}>
  <use xlink:href="#{name}" />
</svg>
