<script>
  import { onMount, onDestroy } from "svelte";
  import Chartist from "chartist";

  export let series = [];
  export let labels = [];

  export let height = 300;

  let chart;
  let chartElm;

  onMount(() => {
    chart = new Chartist.Bar(
      chartElm,
      {
        labels: labels,
        series: series
      },
      {
        height: height,
        donut: true,
        total: 100,
        showLabel: false,
        donutWidth: 0,
        width: 0,
        chartPadding: 0,
        labelOffset: 0,
        axisX: {
          showGrid: false,
          showLabel: false
        },
        axisY: {
          // showGrid: false,
          showLabel: true,
          onlyInteger: true
        },
      }
    );

    chart.on("draw", function(data) {
      if (data.type === "grid" && data.index == 0) {
        // data.element._node.setAttribute('x1', 0)
      }

      if (data.type === "grid" && data.index !== 0) {
        // data.element.remove();
      }
    });
  });

  onDestroy(() => {
    chart.detach();
  });
</script>

<style>
  .bar-graph {
    position: relative;
    width: 100%;
  }

  :global(.bar-graph .ct-bar) {
    /* stroke-linejoin: round;
    stroke-linecap: round; */
  }

  :global(.bar-graph .ct-series-a .ct-bar) {
    stroke: color(red);
  }

  :global(.bar-graph .ct-series-b .ct-bar) {
    stroke: #fff;
  }

  :global(.bar-graph .ct-label.ct-vertical.ct-start) {
    text-align: left;
    justify-content: flex-start;
  }

  :global(.bar-graph .ct-grid.ct-vertical) {
    /* stroke-dasharray: 0px; */
    /* stroke:color(gray); */
  }
</style>

<div class="bar-graph">
  <div class="height" style="height: {height};">
    <div class="chart" bind:this={chartElm} />
  </div>
</div>
