<script>
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import Metas from "../Metas.svelte";
  import EmbeddedVideo from "../EmbeddedVideo.svelte";

  export let id = 0;
  export let title = "";
  export let data = {};
  export let type = "standard";
  export let published = "";
</script>

<style>
  .video-post {
    padding-bottom: 48px;

    .h5 {
      color: color(red);
      margin-bottom: 24px;
    }

    .text {
      margin-bottom: 14px;
      width: 90%;
    }
  }

  .video {
    margin-bottom: 16px;
  }
</style>

<div class="feed-item feed-item--std video-post">
  <h2 class="h5">{title}</h2>

  {#if data.video}
    <div class="video">
      <EmbeddedVideo iframe={data.video} />
    </div>
  {/if}

  <div class="text">
    {#if data.excerpt}
      {@html data.excerpt}
    {/if}
  </div>

  <Metas {published} />
</div>
