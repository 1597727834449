<script>
  import Illustration from "../components/Illustration.svelte";
  import { onMount, onDestroy } from "svelte";
  import { fly, fade } from "svelte/transition";
  import Glide from "@glidejs/glide";
  import api from "api";

  export let id = -1;

  let post = null;
  let confettiElm;
  let slider;
  let sliderElm;

  onMount(() => {
    api
      .get(`completed-challenge/${id}`)
      .then(res => {
        post = res.data.data;
      })
      .catch(error => {});
  });

  $: if (sliderElm && post.attachments.length > 1) {
    slider = new Glide(sliderElm, {
      type: "carousel",
      perView: 1,
      rewind: false,
      // bound: true,
      gap: 24,
      peek: {
        before: 0,
        after: 200
      }
    });

    slider.mount();
  }

  onDestroy(() => {
    if (slider) {
      slider.destroy();
    }
  });
</script>

<style>
  section {
    width: 100vw;
    min-height: 100vh;
    background: #fff;
    padding-bottom: 80px;
  }

  .top {
    background: color(red);
    color: #fff;
    --illu-fill: color(red);
    --illu-stroke: #fff;
    --illu-stroke-alt: #fff;
    padding: 40px 0 152px 0;
    position: relative;

    &.small {
      padding-bottom: 80px;
    }
  }

  .attachments {
    position: relative;
    width: 100%;
    padding-left: 24px;
    margin-top: -104px;
    overflow: hidden;

    .image {
      width: 100%;
      padding-bottom: 133.142%;
      background: color(gray);
      border-radius: 16px;
      overflow: hidden;
      box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.15);
      position: relative;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .glide__slides,
    .glide__track {
      overflow: visible !important;
    }

    &.static {
      padding-right: 24px;
      width:80%;
      max-width:350px;
    }

    padding-bottom: 40px;
    margin-bottom: -40px;
  }

  .summary {
    margin-top: 40px;
    display: flex;
    align-items: center;
    background: color(off-white);
    padding: 32px;
    border-radius: 16px;

    .h5 {
      color: color(red);
    }

    .left {
      flex: 1 1;
    }

    .right {
      flex: 0 0 80px;
      margin-left: 16px;
    }

    .entry {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  :global(.completed-challenge .summary .right svg) {
    width: 100%;
    height: auto;
    max-width: 80px;
  }

  .description {
    margin-top: 56px;
    /* background: color(gray); */
    /* padding: 24px 24px 8px 24px; */
    /* border-radius: $border-radius; */
    /* border-bottom:1px solid color(gray);
    padding-bottom: 32px; */

    &::after {
      content: "";
      clear: both;
      display: table;
    }

    p {
      margin-bottom: 16px;
    }
  }

  :global(.completed-challenge .description svg) {
    float: left;
    margin: 0 16px 8px 0;
  }

  .participants {
    margin-top: 56px;

    .overline {
      margin-bottom: 8px;
    }

    .entries {
      lost-flex-container: row;
      margin-bottom: -$gutter;
    }

    .entry {
      lost-column: 1/2;

      margin-bottom: $gutter;
      font-size: 0.75rem;

      .team {
        opacity: 0.5;
        line-height: 1;
      }
    }
  }

  .confetti {
    position: absolute;
    left: 50%;
  }
</style>

<section class="completed-challenge">
  {#if post}
    <div class="top" in:fly={{ y: -100 }} class:small={!post.attachments.length}>
      <div class="container">
        <Illustration name={post.challenge.icon} />
        <div class="overline">Fullført utfordring</div>
        <h1 class="h4">{post.challenge.name}</h1>
      </div>

      <svg width="375" height="68" viewBox="0 0 375 68" class="wave-1">
        <path
          d="M302.283 21.4427C176.282 59.84 71.1523 39.4272 0
          11.619V68H375V0.171021C351.198 6.81757 326.942 13.9377 302.283
          21.4427Z"
          fill="white" />
      </svg>
    </div>
    {#if post.attachments && post.attachments.length}
      <div
        class="glide attachments"
        class:static={post.attachments.length < 2}
        bind:this={sliderElm}
        in:fly={{ x: 100 }}>
        <div class="glide__track" data-glide-el="track">
          <div class="glide__slides">
            {#each post.attachments as image}
              <div class="glide__slide">
                <div class="image ">
                  <img
                    src="{image.url}?w=350&h=466&fit=crop"
                    alt=""
                    width="350"
                    height="466" />
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    {/if}
    <div class="confetti" bind:this={confettiElm} />
    {#if post.description}
      <div class="container" in:fly={{ y: 100 }}>
        <div class="description">
          <Illustration name="edit" />
          <p>{post.description}</p>
        </div>
      </div>
    {/if}

    <div class="container">
      <div class="summary">
        <div class="left">
          <div class="entry">
            <div class="overline">Kategori</div>
            <div class="h5">
              {post.challenge.category === 'helse' ? 'Helse' : 'Samfunn'}
            </div>
          </div>
          <div class="entry">
            <div class="overline">Poeng</div>
            <div class="h5">{post.challenge.points} poeng</div>
          </div>
        </div>
        <div class="right">
          <Illustration name={post.challenge.category} />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="participants">
        <div class="overline">Deltagere</div>
        <div class="entries">
          {#each post.participants as p}
            <div class="entry">
              <div class="name">{p.first_name} {p.last_name}</div>
              <div class="team">{p.team.name}</div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {:else}
    <!-- loader -->
  {/if}

</section>
