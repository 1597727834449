<script>
  import { fade } from "svelte/transition";
  import Metas from "../Metas.svelte";
  import StandardPost from "./StandardPost.svelte";
  import MilestonePost from "./MilestonePost.svelte";
  import VideoPost from "./VideoPost.svelte";

  export let data;
</script>

{#if data.type === 'standard'}

  <StandardPost {...data}  />
{/if}

{#if data.type === 'milestone'}
  <MilestonePost {...data}  />
{/if}

{#if data.type === 'video'}
  <VideoPost {...data}  />
{/if}
