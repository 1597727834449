<script>
  import Illustration from "./Illustration.svelte";
  export let name = "";
</script>

<style>
  .badge {
    width: 100%;
    position: relative;
    max-width: 120px;
  }

  .height {
    padding-bottom: 100%;
    position: relative;
  }

  .icon {
    position: absolute;
    width: 100%;
    height: 100%;
    background: color(red);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px;
  }

  :global(.badge .icon svg) {
    --illu-stroke-alt: #fff;
    --illu-stroke: #fff;
    --illu-fill: #fff;

    max-width: 64px;
    height: auto;
  }
</style>

<div class="badge">
  <div class="height">
    <div class="icon">
      <Illustration {name} />
    </div>
  </div>
</div>
